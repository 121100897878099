import zhHans from "vuetify/es5/locale/zh-Hans";

export default {
  $vuetify: {
    ...zhHans,

    AppTitle: "元宇宙培训管理后台",
    Enterprise: "上海临兵信息科技有限公司",
    Account: {
      Label: "账号",
    },
    Password: {
      Label: "密码",
      LabelOrigin: "原密码",
      LabelNew: "新密码",
      LabelRepeat: "重复新密码",
    },
    Login: "登录",
    Logout: "退出登录",
    PasswordModity: "修改密码",
    Welcome: "欢迎回来",
    Submit: "提交",
    Cancel: "取消",
    Operation: "操作",
  },
};
