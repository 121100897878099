<template>
  <div id="PanelMain" class="PanelMain">
    <v-container fluid>
      <v-row>
        <!-- <v-col v-if="panel.New">
          <v-btn color="blue darken-1" class="white--text mr-4" @click="onNew">
            <v-icon dark>mdi-plus</v-icon>
            <span>{{ panel.New.Name }}</span>
          </v-btn>
        </v-col>
        <v-col v-if="panel.Buttons">
          <v-btn
            v-for="(btn, index) in panel.Buttons"
            :key="index"
            color="blue darken-1"
            class="white--text mr-4"
            @click="onButton(btn)"
          >
            <span>{{ btn.Name }}</span>
          </v-btn>
        </v-col>-->
        <v-col>
          <v-btn
            v-if="panel.New"
            color="blue darken-1"
            class="white--text mr-4"
            @click="onNew"
          >
            <v-icon dark>mdi-plus</v-icon>
            <span>{{ panel.New.Name }}</span>
          </v-btn>
          <v-btn
            v-for="(btn, index) in panel.Buttons"
            :key="index"
            color="blue darken-1"
            class="white--text mr-4"
            @click="onButton(btn)"
          >
            <span>{{ btn.Name }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="panel.Keywords && panel.Keywords.length">
        <v-col v-for="(keyword, index) in panel.Keywords" :key="index">
          <!-- <v-text-field
            v-model="keyword.Value"
            :name="keyword.Name"
            :label="keyword.Hint ? keyword.Hint : keyword.Title"
            :type="keyword.InputType ? keyword.InputType : 'text'"
            :suffix="keyword.Suffix"
            :disabled="keyword.Disabled"
            outlined
            clearable
            dense
          ></v-text-field>-->
          <JWAFormItem
            :ref="'keyword' + index"
            :item="keyword"
            :index="index"
            @form="onForm"
          ></JWAFormItem>
        </v-col>
        <v-col>
          <v-btn
            color="blue darken-1"
            class="white--text mr-4"
            @click="onKeywords()"
          >
            <span>搜索</span>
          </v-btn>
          <v-btn
            v-for="(btn, index) in panel.KeywordButtons"
            :key="index"
            color="blue darken-1"
            class="white--text mr-4"
            @click="onKeywordButton(btn)"
          >
            <span>{{ btn.Name }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <PanelTable
            v-if="panel.Type == PanelType.Table"
            ref="panel"
            class="jw-s100"
            :table-height="tableHeight"
            :panel="panel"
            :keywords="keywords"
            @form="onForm"
          ></PanelTable>
          <PanelCalendar
            v-else-if="panel.Type == PanelType.Calendar"
            ref="panel"
            class="jw-s100"
            :panel="panel"
            @form="onForm"
          ></PanelCalendar>
          <PanelCard
            v-else-if="panel.Type == PanelType.Card"
            ref="panel"
            class="jw-s100"
            :card="card"
            @form="onForm"
          ></PanelCard>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-alert :value="err.show" type="error" dense text transition>{{
            err.info
          }}</v-alert>
          <v-alert :value="success.show" type="success" dense text transition>{{
            success.info
          }}</v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import "../jwapp/jw.css";
import jw from "../jwapp/jw.js";
import jwa from "./jw.aman";
// import JWAForm from "./Form.vue";
import JWAFormItem from "./FormItem.vue";
import PanelCalendar from "./PanelCalendar.vue";
import PanelCard from "./PanelCard.vue";
import PanelTable from "./PanelTable.vue";

export default {
  name: "APanelMain",

  components: {
    // JWAForm,
    PanelTable,
    PanelCalendar,
    PanelCard,
    JWAFormItem,
  },

  props: {
    panel: {
      type: Object,
    },
    tableHeight: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      ValueType: jwa.ValueType,
      PanelType: jwa.PanelType,

      calendar: {
        Start: new Date().toISOString().substr(0, 10),
        Events: [],
        DateTap: {},
        EventStart: null,
        EventEnd: null,
      },
      list: {
        Columns: [],
        Rows: [],
      },
      pagination: null,
      page: 1,
      keywords: {},
      card: {},

      err: {
        show: false,
        info: "",
      },
      success: {
        show: false,
        info: "",
      },
    };
  },

  //   beforeMount() {
  //     this.doRoute(this.$route);
  //   },

  mounted() {
    var that = this;
    if (!this.$store.ToastError) {
      this.$store.ToastError = function (err) {
        that.err = {
          show: true,
          info: err.Info,
        };
        if (this.showErrorId) {
          clearTimeout(this.showErrorId);
        }
        that.showErrorId = setTimeout(function () {
          that.err = {
            show: false,
            info: "",
          };
        }, 3000);
      };
    }

    if (!this.$store.ToastSuccess) {
      this.$store.ToastSuccess = function (info) {
        that.success = {
          show: true,
          info: info,
        };
        if (that.showSuccessId) {
          clearTimeout(this.showSuccessId);
        }
        this.showSuccessId = setTimeout(function () {
          that.success = {
            show: false,
            info: "",
          };
        }, 3000);
      };
    }
  },

  //   watch: {
  //     $route(route) {
  //       this.doRoute(route);
  //     },
  //   },

  methods: {
    doRoute() {
      this.keywords = {}; // 进入当前页面前先清除关键字搜索
      var panel = this.$refs.panel;
      if (panel && panel.doContent) {
        panel.doContent();
      } else {
        this.doContent();
      }
    },

    doContent(limit) {
      var client = this.$store.state.client;
      var panel = this.panel;
      var that = this;
      var api = panel.ContentApi;
      switch (panel.Type) {
        case jwa.PanelType.Table: {
          client
            .Post(api, {
              data: {
                Limit: limit,
              },
            })
            .then(function (res) {
              if (!res) {
                return;
              }
              that.list = res.Content;
              that.pagination = res.Pagination;
            })
            .catch(function (err) {
              that.$store.ShowError(err.Info);
            });
          break;
        }
        case jwa.PanelType.Calendar: {
          var start = that.calendar.EventStart;
          var end = that.calendar.EventEnd;
          if (start && end) {
            this.getCalendarEvents({ start, end });
          }
          break;
        }
        case jwa.PanelType.Card: {
          client
            .Post(api, {})
            .then(function (res) {
              if (!res) {
                return;
              }
              that.card = res;
            })
            .catch(function (err) {
              that.$store.ShowError(err.Info);
            });
          break;
        }
      }
    },

    doButton(button, keywords) {
      if (!button) {
        return;
      }
      var action = button.Action;
      var client = this.$store.state.client;
      var that = this;
      var api = button.Api;
      var key = button.Key;
      if (keywords) {
        key = jw.utils.Copy(keywords, key);
      }
      switch (action) {
        case jwa.PanelButtonAction.Api: {
          if (api) {
            client
              .Post(api, {
                data: {
                  Key: key,
                },
              })
              .then(function () {
                that.changeContentPage();
              })
              .catch(function (err) {
                that.$store.ShowError(err.Info);
              });
          }
          break;
        }
        case jwa.PanelButtonAction.Form: {
          if (api) {
            client
              .Post(api, {
                data: {
                  Key: key,
                },
              })
              .then(function (res) {
                if (!res) {
                  return;
                }
                // that.form = res;
                // that.submit = button.Submit;
                // that.showForm = true;

                that.$emit("form", {
                  form: res,
                  submit: button.Submit,
                  showForm: true,
                });
              })
              .catch(function (err) {
                that.$store.ShowError(err.Info);
              });
          }
          break;
        }
        // case jwa.PanelButtonAction.Download: {
        //   window.open("https://pic4.zhimg.com/v2-efd4f4517d5bdb43858a04f7e4ff5f7f_r.jpg");
        //   break;
        // }
        case jwa.PanelButtonAction.Report: {
          if (api) {
            client
              .Post(api, {
                data: {
                  Key: key,
                },
              })
              .then(function (res) {
                window.open(res.Url);
              })
              .catch(function (err) {
                that.$store.ShowError(err.Info);
              });
          }
          break;
        }
        default: {
          // TODO
          break;
        }
      }
    },

    onNew() {
      var button = this.panel.New;
      this.doButton(button);
    },

    onButton(btn) {
      // console.log("click", btn.Name);
      this.doButton(btn);
    },

    onKeywordButton(btn) {
      this.doButton(btn, this.getKeywords());
    },

    changeContentPage() {
      var page = this.page;
      var p = this.pagination;
      var limit;
      if (p) {
        limit = {
          Start: (page - 1) * p.NumPerPage,
          Count: p.NumPerPage,
        };
      }
      this.doContent(limit);
    },

    onForm(res) {
      this.$emit("form", res);
    },

    onSubmit() {
      var that = this;
      var panel = that.$refs.panel;
      if (panel && panel.changeContentPage) {
        that.$nextTick(function () {
          panel.changeContentPage();
        });
      } else {
        that.changeContentPage();
      }
    },

    onKeywords() {
      this.keywords = this.getKeywords();
    },

    getKeywords() {
      var that = this;
      var panel = this.panel;
      var keywords = {};
      jw.utils.ArrayForeach(panel.Keywords, function (keyword, formIndex) {
        // var key = keyword.Name;
        // var val = keyword.Value;
        // keywords[key] = val;
        var key = keyword.Name;
        var itemRef = that.$refs["keyword" + formIndex];
        if (itemRef) {
          if (itemRef[0]) {
            itemRef = itemRef[0];
          }
        }
        var val = itemRef.getValue(key, formIndex);
        keywords[key] = val;
      });
      return keywords;
    },
  },
};
</script>
